html {
  scroll-behavior: smooth;
  font-size: 18px;
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy'), url('./fonts/Gilroy-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: bold;
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.woff2') format('woff2');
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
