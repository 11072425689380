.bg-translucent {
  background-color: white;
}
.z-99 {
  z-index: 99;
}

@supports (backdrop-filter: saturate(180%) blur(20px)) or (-webkit-backdrop-filter: saturate(180%) blur(20px)) {
  .bg-translucent {
    background-color: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
}
.waterwave {
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
  height: 400px;
  width: 400px;
}

@media screen and (min-width: 640px) {
  .waterwave {
    height: 32vw;
    width: 32vw;
    /* transform: scale(0.85);
    transform-origin: bottom right; */
  }
}

input,
textarea {
  padding: 0.65rem 1rem;
  margin-bottom: 0.5rem;
}
